<template>
  <div class="mode-select">
    <ul>
      <li :class="{ active: dataset === 'items' }" @click="dataset = 'items'">
        Items
      </li>
      <li
        :class="{
          active: dataset === 'components',
        }"
        @click="dataset = 'components'">
        Components
      </li>
      <li
        :class="{
          active: dataset === 'channels',
        }"
        @click="dataset = 'channels'">
        Channels
      </li>
    </ul>
  </div>

  <div class="grid-view">
    <AgGridVue
      style="width: 100%; min-height: 700px"
      class="ag-theme-alpine"
      :class="{ 'no-data': !rowData }"
      @gridReady="onGridReady"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :rowSelection="'single'"
      :pagination="true"
      :paginationAutoPageSize="true"
      :loadingOverlayComponent="loadingOverlayComponent">
    </AgGridVue>

    <p class="error grid-error-overlay" v-if="errorTextFromAPI">
      {{ errorTextFromAPI }}
    </p>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { loadDefaultFrom, loadDefaultTo } from "@/helpers/dateTimes";
  import {
    OPERATORTYPEVENUE,
    OPERATORTYPEVENDOR,
    OPERATORTYPEOUTLET,
  } from "@/constants/operatorTypes.ts";
  import {
    ORDERCHANNELCLICKANDCOLLECT,
    ORDERCHANNELWALKUP,
  } from "@/constants/orderChannels.ts";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";

  export default {
    components: {
      AgGridVue,
      /* eslint-disable vue/no-unused-components */
      LoadingSpinner: LoadingSpinner,
    },

    props: {
      dateFrom: {
        type: String,
        default: loadDefaultFrom(),
      },
      dateTo: {
        type: String,
        default: loadDefaultTo(),
      },
    },

    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        data: [],
        dataset: "items",
        errorTextFromAPI: null,
        gridApi: null,
        gridColumnApi: null,
        rowData: null,
        vendors: [],
        loadingOverlayComponent: "LoadingSpinner",
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: true,
          minWidth: 100,
          width: 150,
          flex: 1,
        },
      };
    },

    computed: {
      // Standardisation failure within the API, deal with this prior to significant integration.
      columnAmount() {
        switch (this.dataset) {
          default:
            return "amount";
          case "components":
            return this.operatorType == OPERATORTYPEVENUE
              ? "amountAdded"
              : "amount";
          case "channels":
            return this.operatorType == OPERATORTYPEVENUE
              ? "orderCount"
              : "amount";
        }
      },

      columnGrossRevenue() {
        if (
          this.operatorType == OPERATORTYPEVENUE &&
          this.dataset == "components"
        ) {
          return "revenue";
        }

        return this.dataset == "components"
          ? "revenueBase"
          : "revenueConfigured";
      },

      columnDefs() {
        const columns = [
          {
            headerName: "",
            valueGetter: "node.rowIndex + 1",
            minWidth: 56,
            width: 56,
            pinned: "left",
          },
        ];

        if (this.dataset != "channels") {
          columns.push({
            headerName: "Name",
            field: "name",
            flex: 2,
          });
        } else {
          columns.push({
            headerName: "Channel",
            field: this.operatorType == OPERATORTYPEVENUE ? "name" : "channel",
            pinned: "left",
            width: 200,
            valueFormatter: ({ value }) => {
              switch (value) {
                case "ClickCollect":
                  return "Click & Collect";
                case "WalkUp":
                  return "Walk Up";
                case "TableService":
                  return "Table Service";
                default:
                  return value;
              }
            },
          });
        }

        if (
          this.operatorType == OPERATORTYPEVENUE &&
          this.dataset != "channels"
        ) {
          columns.push({
            headerName: "Vendor",
            field: "vendorName",
            sortable: false,
          });
        }

        columns.push({
          headerName: "Amount",
          field: this.columnAmount,
          width: 50,
        });

        columns.push({
          headerName: "Gross Revenue",
          field: this.columnGrossRevenue,
          valueFormatter: ({ value }) => {
            return this.formatMoney(value);
          },
        });

        if (this.dataset != "channels") {
          columns.push({
            headerName: "Online",
            field: "perChannel",
            width: 120,
            valueFormatter: ({ value }) => {
              return this.getOrderChannelValue(
                value,
                ORDERCHANNELCLICKANDCOLLECT
              );
            },
          });

          columns.push({
            headerName: "Table",
            field: "perChannel",
            width: 120,
            valueFormatter: ({ value }) => {
              return this.getOrderChannelValue(
                value,
                ORDERCHANNELCLICKANDCOLLECT
              );
            },
          });

          columns.push({
            headerName: "Walk Up",
            field: "perChannel",
            width: 120,
            valueFormatter: ({ value }) => {
              return this.getOrderChannelValue(value, ORDERCHANNELWALKUP);
            },
          });
        }

        columns.push({
          colId: "percentGrossRevenue",
          headerName: "% of Gross Revenue",
          field: this.columnGrossRevenue,
          valueFormatter: ({ value }) => {
            return (
              ((value / this.getTotalGrossRevenue()) * 100).toFixed(1) + " %"
            );
          },
        });

        return columns;
      },

      ...mapGetters({}),
    },

    watch: {
      dataset() {
        this.rowData = this.hydrateDataWithVendorName(this.data[this.dataset]);
      },
    },

    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.getSummary();

        this.gridColumnApi.applyColumnState({
          state: [{ colId: "percentGrossRevenue", sort: "desc" }],
          defaultState: { sort: null },
        });
      },

      getTotalGrossRevenue() {
        return this.data[this.dataset].reduce(
          (sum, item) => (sum += item[this.columnGrossRevenue]),
          0
        );
      },

      getSummary() {
        this.gridApi.showLoadingOverlay();

        this.errorTextFromAPI = null;

        this.getSummaryEndpoint()
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject("Could not download summary.");
          })
          .then((data) => {
            // set items/channels/alterations based on dataset prop:
            this.data = data;
            this.vendors = data.vendors;

            this.rowData = this.hydrateDataWithVendorName(data[this.dataset]);

            this.gridApi.hideOverlay();
          })
          .catch((err) => {
            window.log.error(err);
            this.gridApi.hideOverlay();
            this.rowData = null;
            this.errorTextFromAPI = err;
          });
      },

      getSummaryEndpoint() {
        switch (this.operatorType) {
          case OPERATORTYPEVENDOR:
            return store.state.apiPrivate.client.endpoints.vendorSummaryItems.get(
              {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
              },
              this.operatorId
            );
          case OPERATORTYPEOUTLET:
            return store.state.apiPrivate.client.endpoints.outletSummaryItems.get(
              {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
              },
              this.operatorId
            );
          case OPERATORTYPEVENUE:
            return store.state.apiPrivate.client.endpoints.venueSummarySales.get(
              {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
              },
              this.operatorId
            );
          default:
            window.log.error(
              "[❌] Failed to resolve a transaction endpoint to use for your operation type."
            );
        }
      },

      hydrateDataWithVendorName(data) {
        return data.map((item) => {
          if (item.vendorId) {
            const vendor = this.vendors.find(
              (vendor) => vendor.id === item.vendorId
            );
            item.vendorName = vendor?.name;
          }
          return item;
        });
      },

      getOrderChannelValue(value, channel) {
        if (!value) return this.formatMoney(0);

        const channelValue = value.find(
          (item) => item.name === channel || item.channel === channel
        );

        if (!channelValue) return this.formatMoney(0);

        return this.formatMoney(
          this.datset == "items"
            ? channelValue.revenueConfigured
            : channelValue.revenueBase
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .hide-grid {
    display: none;
  }

  .mode-select {
    width: 100%;
    ul {
      display: flex;
      gap: 1rem;
      margin-bottom: 0;
      margin-top: 1rem;

      li {
        cursor: pointer;

        background: none;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.25rem 0.75rem;
        border-radius: $input_radius;

        &:hover {
          background: $col_alpha-lightest;
        }

        &.active {
          background: $col_alpha-lighter;
          color: $col_offwhite;
        }
      }
    }
  }
</style>
