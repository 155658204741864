<template>
  <div class="main-content">
    <h3 class="page-heading">Performance</h3>
    <div class="cards-container" :class="layout">
      <DateRanger
        :startDate="this.dateFrom"
        :endDate="this.dateTo"
        @update="updateDateRange" />

      <PerformanceSummary
        ref="itemsSummary"
        :dateFrom="dateFrom"
        :dateTo="dateTo" />
    </div>
  </div>
</template>

<script>
  import router from "@/router";
  import { mapGetters } from "vuex";
  import { screenDetector } from "@/helpers/screenDetector";
  import PerformanceSummary from "@/components/Sales_/PerformanceSummary";
  import DateRanger from "@/components/Inputs/DateRanger";

  export default {
    components: {
      PerformanceSummary,
      DateRanger,
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        dateFrom: this.$route.params.dateFrom,
        dateTo: this.$route.params.dateTo,
        showChart: true,
        layout: "desktop",
        loading: false,
      };
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      updateIfDesktopLayoutState() {
        this.layout = screenDetector();
      },

      updateDateRange(data) {
        router.push({
          name: "/sales/performance/dates",
          params: {
            operatorType: this.operatorType,
            operatorId: this.operatorId,
            dateFrom: data.startDate,
            dateTo: data.endDate,
          },
        });
      },
    },
    beforeMount() {
      this.updateIfDesktopLayoutState();
      window.addEventListener("resize", this.updateIfDesktopLayoutState);
    },

    unmounted() {
      window.removeEventListener("resize", this.updateIfDesktopLayoutState);
    },
  };
</script>

<style lang="scss">
  .revenue-payout-summary {
    display: flex;
    flex-direction: row;
    &__data {
      width: 50%;
      max-width: 800px;
      min-width: 300px;
      .xl-desktop &,
      .desktop & {
        margin-left: 4rem;
      }
    }
    &.tablet,
    &.mobile {
      flex-direction: column;
    }
    &__title,
    &__sub-title {
      color: #aaa;
      margin: 0;
    }
    &__value {
      align-items: end;
      height: 50px;
      p {
        font-size: 2rem;
        margin: 0;
      }
      span.percentage {
        margin-left: 1rem;
        line-height: 2rem;
      }
    }
    &__sub-title {
      font-size: 0.7rem !important;
      margin-bottom: 0.1rem !important;
    }
    &__sub-value {
      align-items: center;
      p {
        font-size: 1.6rem;
        margin: 0;
      }
    }
    &__revenue-content {
      align-items: flex-end;
      & div:first-child {
        .xl-desktop &,
        .desktop {
          margin-right: 3rem;
        }
      }
    }
  }

  span.growth-percentage-chip {
    background: $col_beta;
    border-radius: $card_radius;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0 1rem;
    &.declined {
      background: $col_alpha;
    }
  }
</style>
